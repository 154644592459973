/* @import "~antd/dist/antd.css"; */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* || GENERAL STYLES */
  html {
    @apply h-full
  }

  body {
    @apply bg-yellow-50 text-black-900 font-sans h-full;
  }

  #root {
    @apply h-full
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-serif font-bold;
  }

  h2 {
    @apply mt-6 text-3xl font-extrabold text-black-900;
  }

  a {
    @apply text-green-900 font-medium hover:text-green-900;
  }

  /* || FORM STYLES */
  .text-input {
    @apply shadow-lg appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm focus:ring-1 font-sans;
  }

  .checkbox-input {
    @apply h-4 w-4 text-green-900 focus:ring-green-900 border-gray-300 rounded;
  }

  .btn-primary {
    @apply w-full shadow-lg justify-center py-2 px-4 border border-green-900 rounded-md text-sm font-medium text-white bg-green-900 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900;
  }

  .btn-primary:disabled {
    @apply w-full justify-center py-2 px-4 border border-green-200 rounded-md text-sm font-medium text-white bg-green-200 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200;
  }

  .btn-secondary {
    @apply w-full shadow-lg justify-center py-2 px-4 border border-yellow-900 rounded-md text-sm font-medium text-white bg-yellow-900 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-900;
  }

  .btn-secondary:disabled {
    @apply w-full justify-center py-2 px-4 border border-yellow-200 rounded-md text-sm font-medium text-white bg-yellow-200 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-200;
  }

  .btn-outline {
    @apply w-full justify-center py-2 px-4 border border-green-900 rounded-md hover:text-white text-sm font-medium text-green-900 bg-transparent hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900;
  }

  .btn-social {
    @apply shadow-lg w-full inline-flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm font-medium hover:bg-gray-50;
  }

  .country-list {
    @apply rounded-lg shadow-sm;
  }

  .react-tel-input .country-list .search-box {
    @apply text-input;
  }

  .btn-link {
    @apply text-green-900 font-medium hover:underline;
  }

  .btn-user-nav {
    @apply text-black-900 font-medium;
  }

  .btn-link:disabled {
    @apply text-green-200 font-medium hover:no-underline cursor-wait;
  }

  .nav-link {
    @apply border-opacity-0 border-b-2 hover:text-green-900 hover:border-green-900 px-3 py-2 text-sm font-medium;
  }

  .nav-link-active {
    @apply px-3 py-2 text-sm font-medium border-b-2 border-green-900 border-opacity-100 text-green-900 hover:text-green-900;
  }

  .ant-picker,
  .ant-picker-focused,
  .ant-picker:hover {
    @apply text-input w-full;
  }

  .ant-picker {
    @apply w-full;
  }

  .ant-picker-panel-container {
    @apply rounded-md z-50;
    z-index: 1000;
  }

  [multiple]:focus {
    @apply border-gray-300;
  }

  .ant-form-item-control-input:focus-within {
    @apply border-green-900;
  }

  .ant-form-item-control-input {
    @apply border-green-900;
  }

  .ant-input:focus {
    @apply border-green-900;
  }

  input[type="file"]::file-selector-button {
    @apply text-white bg-green-900 hover:bg-green-800 font-medium text-sm cursor-pointer border-0 border-r border-solid border-gray-300 py-2.5 pl-8 pr-4;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
  }
}

/* class for map box */
.map-container {
  height: 400px;
}

/* || REACT PHONE INPUT */
.react-tel-input .flag-dropdown {
  border: none !important;
  background-color: transparent !important;
}

.react-tel-input:hover {
  border: none !important;
  background-color: transparent !important;
}

.z-50 {
  z-index: 50;
}

.selected-flag:hover,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.selected-flag {
  background-color: transparent !important;
  padding-left: 14px !important;
}

.react-tel-input .country-list {
  border-radius: 0.375rem !important;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-family: "Montserrat" !important;
}

.pac-container {
  border-radius: 0.375rem !important;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-family: "Montserrat" !important;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.pac-item {
  padding-top: 8px;
  padding-bottom: 8px;
}

.react-tel-input .country-list .search {
  padding: 10px !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-image: url("./assets/images/icons/clear.svg") !important;
}

.signup-image {
  background-image: url("https://images.unsplash.com/photo-1539145632639-c13729854e15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80");
  background-position: center;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #3c6e71 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #3c6e71 !important;
}

.ant-picker-today-btn {
  color: #3c6e71 !important;
}

.ant-picker-dropdown {
  /* z-index: 1000; */
  background-color: #3c6e71;
}

.ant-picker-header-view button:hover {
  color: #3c6e71 !important;
}

.ant-progress-bg {
  height: 4px !important;
}

.ant-table-wrapper {
  min-width: 100%;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.fc-v-event {
  background-color: #3c6e71;
}

.bold123 {
  font-weight: bolder;
}

div>div>.test {
  z-index: 2000000;
}

.test {
  position: absolute;
  background-color: #3c6e71;
}

.mySwal {
  background: #fffced;
}

.mySwal2 {
  width: 700px;
  background: #fffced;
}

.mySwal3 {
  height: inherit;
  background: #fffced;
}

.swal-custom {
  height: 600px;
  width: 37em;
  background: #fffced;
}

.swal2-html-container {
  padding: 20px;
  height: 100%;
}

body.swal2-height-auto {
  height: 100% !important;
}


.ant-radio-wrapper-checked {
  color: #3c6e71;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #3c6e71 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #3c6e71;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #3c6e71;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #3c6e71;
}

.interestSwal {
  background: #fffced;
  position: relative;
}

.interestSwal::before {
  content: "";
  background-image: url('./assets/images/logos/logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.25;
}

.bla {
  width: auto !important;
}

.mapBoxAdressInput {
  background: #e7e7d4;
  border: 1px solid #f7b701;
  border-radius: 10px;
}

.companyNamePen {
  display: none;
}

.companyNamePenSection:hover .companyNamePen {
  display: block;
}

.firstNamePen {
  display: none;
}

.firstNamePenSection:hover .firstNamePen {
  display: block;
}

.emailPen {
  display: none;
}

.EmailPenSection:hover .emailPen {
  display: block;
}

.numPen {
  display: none;
}

.numPenSection:hover .numPen {
  display: block;
}

.landlinePen {
  display: none;
}

.landlinePenSection:hover .landlinePen {
  display: block;
}

.websitePen {
  display: none;
}

.websitePenSection:hover .websitePen {
  display: block;
}

.instaPen {
  display: none;
}

.instaPenSection:hover .instaPen {
  display: block;
}

.facePen {
  display: none;
}

.facePenSection:hover .facePen {
  display: block;
}

.longText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: '70%'
}

.deleteInterest {
  display: none;
}

.CardSection:hover .deleteInterest {
  display: flex;
}

.lastChild:last-of-type {
  border: unset;
}

.swal2-styled.swal2-confirm {
  background-color: #3C6E71 !important;
}

#swal2-title {
  font-size: 25px;
  text-align: center;
}

.redBG {
  background-color: #a60e0e;
}

.noMargin {
  margin-bottom: 0px !important;
}

.whiteText {
  color: white !important;
}

.borderLeft {
  border-left: 1px solid black;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  border: black 1px solid;
  padding: 16.5px 14px;
  padding-top: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 8px !important;
  padding-left: 2px !important;
}

.css-kp9ftd-MuiIcon-root {
  display: none !important;
}

.MuiInputBase-inputMultiline:focus {
  outline: none !important;
  box-shadow: none !important;
  ;
}

.required-field::after {
  content: "*";
  color: rgb(185 28 28);
  padding-left: 2px;
}
span.MuiButton-startIcon.MuiButton-iconSizeMedium.css-6xugel{
  display: none;
}