.react-geocoder input {
    background-color: rgb(255, 255, 255);
    height: 55px;
    width: 100%;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 22px;
    border: 0px;
    font-weight: 300;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 32px;
    padding-right: 0px !important;
}
.react-geocoder-item{
    background-color: rgb(249, 248, 247);
    cursor: pointer;
    padding: 16px;
    border-bottom: 1px solid rgb(216, 216, 216);
}
